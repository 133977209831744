import React, { useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "../styles/pages/AboutPage.module.scss";
// import { useWebMountContext } from "../context/PageLoadContext.js";

import BookModel from "../images/books/bookModel.png";

import { StaticImage } from "gatsby-plugin-image";

import { doBounceAnimation } from "../animations/twn_doBounceAnimation";
import { doStaggerAnimation } from "../animations/twn_doStaggerAnimation";

const About = () => {
  // const { setFirstVisitToAboutPage, firstVisitToAboutPage } = useWebMountContext();
  // const [showAnimation] = useState(firstVisitToAboutPage);
  const skillsAndBooksData = useStaticQuery(graphql`
    query getSkills {
      allSkillsJson {
        edges {
          node {
            name
            icon {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
      }
      allBooksJson {
        totalCount
        edges {
          node {
            name
            coverImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  const skills = skillsAndBooksData.allSkillsJson.edges;
  const books = skillsAndBooksData.allBooksJson.edges;

  return (
    <div className={styles.content_wrapper}>
      <SectionAbout />
      <SectionSkills skills={skills} />
      <SectionBooks books={books} />
    </div>
  );
};

export default About;

const SectionAbout = () => {
  const imageRef = useRef();
  const descRef = useRef();

  useEffect(() => {
    doBounceAnimation(imageRef.current, 0.2, "top bottom");
    doBounceAnimation(descRef.current, 0.4, "top bottom");
  }, []);

  return (
    <section className={styles.about_grid}>
      <div className={styles.about_img_wrapper} ref={imageRef}>
        <StaticImage
          className={styles.about_img_frame}
          src="../images/profileImage.jpg"
          alt="This is really me"
          loading="eager"
          placeholder="dominantColor"
        />
        <div className={styles.about_shadow}></div>
      </div>
      <div className={styles.about_description_wrapper} ref={descRef}>
        <h1>A little bit about me.</h1>
        <p>
          I started as a visual designer that dabbled between graphics, prints and UIUX. Fascinated
          at seeing my dev co-workers bringing UI designs alive, I decided to try out programming
          and enrolled myself in a coding bootcamp around late 2018. I haven't had a full 7 hours of
          sleep since then.
        </p>
        <p>
          Following that, I worked as an Assistant Software Engineer for Land Transport Authority,
          taking on a hybrid role as the sole UX Designer and Front End Developer for the Data
          Science Divison.
        </p>
        <p>
          I see myself as someone who sits very much in between a designer and developer mentality.
          I'm constantly studying and thinking about how to better bridge the gap between the two.
        </p>
      </div>
    </section>
  );
};

const Skill = ({ data }) => {
  const { name, icon } = data.node;
  const image = getImage(icon);
  return (
    <div className={`${styles.skill_wrapper} skill-card`}>
      <div className={styles.skill_logo_container}>
        <GatsbyImage
          className={styles.skill_icon}
          image={image}
          alt="Storybook Icon"
          objectFit="contain"
        />
        <div className={styles.skill_shadow}></div>
      </div>
      <p className={styles.skill_name}>{name}</p>
    </div>
  );
};

const SectionSkills = ({ skills }) => {
  const sectionRef = useRef();
  const textRef = useRef();

  useEffect(() => {
    doBounceAnimation(textRef.current, 0.6, "top bottom");
    doStaggerAnimation(sectionRef.current, ".skill-card");
  }, []);

  return (
    <section ref={sectionRef}>
      <div ref={textRef}>
        <h2>As a UX Engineer...</h2>
        <p className={styles.skills_desc}>
          I approach development with a{" "}
          <a href="https://www.componentdriven.org/">Component-Driven Development (CDD) </a>
          methodology, crafting robust UI components for design systems. Here are some
          tech/methodologies that I'm familiar with.
        </p>
      </div>
      <div className={styles.skills_gridSkills}>
        {skills.map((skill) => (
          <Skill data={skill} key={skill.node.name} />
        ))}
      </div>
    </section>
  );
};

const Book = ({ data }) => {
  const bookName = data.node.name;
  const image = getImage(data.node.coverImage);
  return (
    <div className={`card-book`}>
      <div className={styles.book_wrapper}>
        <div className={styles.book_cover_wrapper}>
          <div className={styles.book_highlight}></div>
          <img src={BookModel} alt="Book Overlay" className={styles.book_overlay} />
          <GatsbyImage image={image} alt={bookName} className={styles.book_cover} />
        </div>
        <div className={styles.book_shadow} />
      </div>
    </div>
  );
};

const SectionBooks = ({ books }) => {
  const sectionRef = useRef();
  const textRef = useRef();

  useEffect(() => {
    doBounceAnimation(textRef.current, 0.4, "top bottom");
    doStaggerAnimation(sectionRef.current, ".card-book");
  }, []);

  return (
    <section ref={sectionRef}>
      <div ref={textRef}>
        <h2>When I'm not staring at a screen...</h2>
        <p>
          Or empty blank walls while ruminating over my potential quarter-life crisis, I try to read
          books. Here are some of my favourites reads in 2021.
        </p>
      </div>
      <div className={styles.book_horizontalScrollWrapper}>
        <div className={styles.book_hScrollInner}>
          <div>
            <div role="list" className={styles.book_gridBooks}>
              {books.map((book, i) => (
                <div role="listitem" key={i}>
                  <Book data={book} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
