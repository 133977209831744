// extracted by mini-css-extract-plugin
export var about_description_wrapper = "AboutPage-module--about_description_wrapper--a07ly";
export var about_grid = "AboutPage-module--about_grid---x0An";
export var about_img_frame = "AboutPage-module--about_img_frame--uuwTX";
export var about_img_wrapper = "AboutPage-module--about_img_wrapper--6O-J5";
export var about_shadow = "AboutPage-module--about_shadow--HFGi9";
export var book_cover = "AboutPage-module--book_cover--Vi6yg";
export var book_cover_wrapper = "AboutPage-module--book_cover_wrapper--7UY9a";
export var book_gridBooks = "AboutPage-module--book_gridBooks--90Yfk";
export var book_hScrollInner = "AboutPage-module--book_hScrollInner--EQ46K";
export var book_highlight = "AboutPage-module--book_highlight--t8ozY";
export var book_horizontalScrollWrapper = "AboutPage-module--book_horizontalScrollWrapper--jVSr3";
export var book_overlay = "AboutPage-module--book_overlay--y6DyZ";
export var book_shadow = "AboutPage-module--book_shadow--Y4YUi";
export var book_wrapper = "AboutPage-module--book_wrapper--ANaZ1";
export var content_wrapper = "AboutPage-module--content_wrapper--4YJiK";
export var pageWrapper = "AboutPage-module--pageWrapper--n9vm+";
export var skill_icon = "AboutPage-module--skill_icon--R3zNW";
export var skill_logo_container = "AboutPage-module--skill_logo_container--3u6Ag";
export var skill_name = "AboutPage-module--skill_name--hEbEh";
export var skill_shadow = "AboutPage-module--skill_shadow--B4F6p";
export var skill_wrapper = "AboutPage-module--skill_wrapper--AGXeN";
export var skills_desc = "AboutPage-module--skills_desc--0KUsD";
export var skills_gridSkills = "AboutPage-module--skills_gridSkills--J2Dgs";